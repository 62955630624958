import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID || '',
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || '',
  site: 'ap1.datadoghq.com',
  service: `app-web-${import.meta.env.VITE_DATADOG_ENV_NAME}`,
  env: import.meta.env.VITE_DATADOG_ENV_NAME,
  version: import.meta.env.VITE_RELEASE_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [import.meta.env.VITE_BACKEND_URL ?? ''],
});

datadogLogs.init({
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || '',
  site: 'ap1.datadoghq.com',
  service: `app-web-${import.meta.env.VITE_DATADOG_ENV_NAME}`,
  env: import.meta.env.VITE_DATADOG_ENV_NAME,
  version: import.meta.env.VITE_RELEASE_VERSION,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

export default function Datadog() {
  return null;
}
